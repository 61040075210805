<template>
  <div class="comment-item">
    <router-link v-if="data.user" :to="`/user/${data.user.id}`" class="comment-item__right right">
      <div class="right__user">
        <img class="right__user-avatar" :src="getUserAvatar(data.user.avatar, true)" alt="">
        <div class="right__user-info">
          <p class="name">{{ getNickname }}</p>
          <div class="role">
            <img :src="getUserRole(data.user.role).icon" alt="">
            <span>{{ getUserRole(data.user.role).text }}</span>
            <p v-if="data.user?.role === 'Company' || data.user?.role === 'Government'">{{ data.user.companyName }}</p>
          </div>
        </div>
      </div>
      <p class="right__text">{{ data.message }}</p>
      <span class="right__date">{{ getFullDate(data.createdAt) }}</span>
    </router-link>
    <div class="comment-item__left">
      <img src="@/assets/svg/icon-check-green.svg" alt="" v-if="!data.isRead"/>
      <img src="@/assets/svg/icon-check-green-double.svg" alt="" v-else/>
    </div>
  </div>
</template>

<script>
import { getUserAvatar, getUserRole } from "@/utils/user";
import { getFullDate } from '@/utils/general'

export default {
  name: "CommentItem",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      getFullDate,
      getUserAvatar,
      getUserRole
    }
  },

  computed: {
    getNickname () {
      return this.data.user?.role === 'Inspector' || this.data.user?.role === 'Lawyer' || this.data.user?.role === 'Company' || this.data.user?.role === 'Government' ? this.getUserName(this.data.user.lastName, this.data.user.firstName, this.data.user.middleName) : '' ||
              this.data.user?.role === 'Admin' || this.data.user?.role === 'Moderator' ? this.data.user.lastName + ' ' + this.data.user.firstName : this.data.user.login
    }
  },

  methods: {
    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.comment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__user {
      display: flex;
      align-items: center;
      gap: 10px;

      &-avatar {
        width: 40px;
        height: 40px;
        border: 1px solid #CE2121;
        border-radius: 50%;
        object-fit: cover;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .name {
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
          color: #343432;
        }

        .role {
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            width: 10px;
            height: 10px;
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 11px;
            color: #9A9A9A;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 11px;
            color: #9A9A9A;
            display: flex;
            align-items: center;
            gap: 5px;

            &:before {
              content: '';
              width: 5px;
              height: 5px;
              background-color: #9A9A9A;
              border-radius: 50%;
            }
          }
        }
      }
    }

    &__text {
      margin-left: 50px;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #343432;
    }

    &__date {
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      color: #9A9A9A;
      margin-left: 50px;
    }
  }
}
</style>
